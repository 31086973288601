import {
  Form,
  Input,
  Button,
  Select,
  Image,
  Row,
  Col,
  Alert
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from "./assets/logo.png"

const validateMessages = {
  required: 'Debe ingresar un valor'
};

const Formulario = () => {

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [params] = useSearchParams();

  const onFinish = async (values: any) => {
    let key: string = params.get('key') ?? '';
    setSuccess(null);
    setError(null);
    setLoading(true);
    const rawResponse = await fetch(process.env.REACT_APP_ENDPOINT as string, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': key
      },
      body: JSON.stringify(values)
    });
    setLoading(false);
    if (rawResponse.status === 401) {
      setError("Llave de autenticación inválida.");
      setSuccess(null);
    } else if (!rawResponse.ok) {
      let jsonBody = await rawResponse.json();
      setError(jsonBody.msg);
      setSuccess(null);
    } else {
      let jsonBody = await rawResponse.json();
      form.resetFields();
      setError(null);
      setSuccess(jsonBody.msg);
    }
  };

  return <>
    <Row justify='center' align='middle'>
      <Col style={{ textAlign: 'center' }} span={18}>
        <Image preview={false} style={{ marginTop: '20px' }} width={'200px'} src={logo} alt="Agencia Carlo Rossi y Cia Ltda." />
      </Col>
    </Row>
    <Row justify='center' align='middle'>
      <Col style={{ textAlign: 'center' }} span={18}>
        <Title style={{ marginTop: '20px' }} level={3}>Formulario Camión</Title>
      </Col>
    </Row>
    <Row justify='center' align='middle'>
      <Col span={18}>
        <Form
          onFinish={onFinish} validateMessages={validateMessages}
          form={form}
          labelCol={{ span: 6 }}
          layout="vertical"
          size={'large'}
        >
          <Form.Item required={true} rules={[{ required: true }]} label="Patente" name="patente">
            <Input allowClear maxLength={6} placeholder='Ingresar Patente' />
          </Form.Item>
          <Form.Item required={true} rules={[{ required: true }]} label="Notificar" name="tipo">
            <Select allowClear placeholder="Seleccionar Evento">
              <Select.Option value="INGRESO">Llegada a Puerto</Select.Option>
              <Select.Option value="BAJADA">Bajada de Camión</Select.Option>
              <Select.Option value="EMBARQUE">Embarque de Camión</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            {error != null ? <Alert style={{ marginBottom: 20 }} message={error} type="error" showIcon /> : null}
            {success != null ? <Alert style={{ marginBottom: 20 }} message={success} type="success" showIcon /> : null}
            <Button style={{ float: 'right' }} type="primary" loading={isLoading} htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  </>;
};

export default Formulario;